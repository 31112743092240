/* eslint-disable consistent-return */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { get } from 'lodash';
import Countdown from 'react-countdown-now';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import dictionary from '../dictionary';
import imgLogo from '../assets/images/logo/logo.png';
import Logo_SESC_Branco from '../assets/images/logo/Logo_SESC_Branco.svg'
import Logo_SESC_Branco_Amarelo from '../assets/images/logo/Logo_SESC_Branco_Amarelo.svg'
import Logo_SESC_Azul_Amarelo from '../assets/images/logo/Logo_SESC_Azul_Amarelo.svg'
import { ComponentBase } from '../base';
import { API_URL } from '../consts';
import { logout, getAxiosConfig } from '../services';
import NAVBAR from '../navbarItens';
import ModalTempoSessao from './ModalTempoSessao';

class NavbarGeral extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      img: null,
      counter: 1200000,
      width: window.innerWidth,
      showSubdropDown: [],
      showModalTempoSessao: false,
    };
    this.togglePopover = this.togglePopover.bind(this);
    this.openDropdown = this.openDropdown.bind(this);
    this.mostraInputVisualizarComo = this.mostraInputVisualizarComo.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.resetCounter = this.resetCounter.bind(this);
    this.expandSubDropdown = this.expandSubDropdown.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.resetCounter);
    document.body.addEventListener('keypress', this.resetCounter);
    window.addEventListener('resize', this.updateDimencaoPage.bind(this));
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.resetCounter);
    document.body.removeEventListener('keypress', this.resetCounter);
  }

  updateDimencaoPage() {
    this.setState({ width: window.innerWidth });
  }

  resetCounter() {
    this.setState({
      counter: 1200000,
      showModalTempoSessao: false,
    });
  }

  getPopoverClass(popover) {
    const { [popover]: isOpen } = this.state;
    return isOpen ? 'show' : 'hidden';
  }

  togglePopover(popover) {
    const { [popover]: isOpen } = this.state;
    this.setState({ [popover]: !isOpen });
    document.body.addEventListener(
      'mouseup',
      (e) => {
        if (
          e.target.className !== null
          && e.target.className.indexOf
          && e.target.className.indexOf('item-popover-fix') === -1
        ) {
          this.setState({ [popover]: false });
        }
      },
      true,
    );
  }

  openDropdown(dropdown) {
    this.setState({
      showSubdropDown: false,
    });
    const { [dropdown]: isOpen } = this.state;
    this.setState({ [dropdown]: !isOpen });
    document.body.addEventListener(
      'click',
      (e) => {
        if (
          e.target.className !== null
          && e.target.className.indexOf
          && e.target.className.indexOf('btn-dropdown') === -1
        ) {
          this.setState({ [dropdown]: false });
        }
      },
      true,
    );
  }

  mostraInputVisualizarComo() {
    const { visualizar_como } = this.state;
    const { input_visualizar_como } = this.state;
    this.setState({ visualizar_como: !visualizar_como });
    this.setState({ input_visualizar_como: !input_visualizar_como });
  }

  handleLogout() {
    const {
 location, match, onLogout, history,
} = this.props;
    const logoutFromApi = () => logout(history);
    const abandonarBanca = () => axios.post(
        `${API_URL}/correcoes/${match.params.id}/abandonar-banca`,
        {},
        getAxiosConfig(),
      );
    const abandonoBanca = queryString.parse(location.search).banca && match.params.id;

    if (abandonoBanca) {
      abandonarBanca().then(logoutFromApi);
    } else {
      logoutFromApi();
    }

    if (onLogout) onLogout();
  }

  shouldShowMenu(item) {
    let hasMenuPermission = !item.permission;
    let hasMenuFeature = !item.feature;

    if (item.permission && this.hasPermission(item.permission)) {
      hasMenuPermission = true;
    }

    if (item.feature && this.hasFeature(item.feature)) {
      hasMenuFeature = true;
    }

    return hasMenuFeature && hasMenuPermission;
  }

  renderDefaultLink = (item) => {
    if (this.shouldShowMenu(item)) {
      return (
        <Link key={item.id} to={item.link} id={item.id}>
          <div className="navbar-item">{item.nome}</div>
        </Link>
      );
    }
  };

  expandSubDropdown(nome) {
    const { showSubdropDown } = this.state;
    this.setState({ showSubdropDown: showSubdropDown === nome ? null : nome });
  }

  renderDropDownMenu(item) {
    const { width, showSubdropDown } = this.state;
    if (this.shouldShowMenu(item)) {
      return (
        <div
          key={item.id}
          className="d-flex align-items-center position-relative"
        >
          {width > 990 && (
            <button
              id={item.id}
              type="button"
              className="menu-dropdown"
              onClick={() => this.openDropdown(item.class)}
            >
              {item.nome}
            </button>
          )}
          <ul
            key={item.id}
            className={`${
              width > 990 ? 'dropdown-desktop' : 'drop-down-mobile'
            } ${this.getPopoverClass(item.class)}`}
            style={{ display: 'grid' }}
          >
            {width < 990 ? (
              <li className="parent-dropdown">{item.nome}</li>
            ) : (
              ''
            )}
            {item.dropdown.map(subItem => (
              <React.Fragment key={subItem.id}>
                {subItem.link
                  && this.shouldShowMenu(subItem)
                  && !subItem.subdropdown && (
                    <Link to={subItem.link} id={subItem.id}>
                      <li>{subItem.nome}</li>
                    </Link>
                  )}
                {subItem.link === undefined
                  && subItem.subdropdown
                  && subItem.subdropdown.some(currentValue => this.shouldShowMenu(currentValue)) && (
                    <button
                      type="button"
                      className={`btn btn-dropdown navbar-button--${
                        subItem.nome === showSubdropDown ? 'open' : 'close'
                      }`}
                      onClick={() => this.expandSubDropdown(subItem.nome)}
                    >
                      {subItem.nome}
                    </button>
                  )}
                {subItem.subdropdown !== undefined
                  ? subItem.subdropdown.map((subDropDown) => {
                      if (this.shouldShowMenu(subDropDown)) {
                        return (
                          <Link
                            key={subDropDown.id}
                            to={subDropDown.link}
                            id={subDropDown.id}
                          >
                            <li
                              className={`${
                                showSubdropDown === subItem.nome
                                  ? 'subDropDownShow'
                                  : 'subDropDownHide'
                              }`}
                            >
                              {subDropDown.nome}
                            </li>
                          </Link>
                        );
                      }

                      return null;
                    })
                  : ''}
              </React.Fragment>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  }

  renderMenu() {
    const isActive = item => item.show;
    const mapItem = item => (item.dropdown
        ? this.renderDropDownMenu(item)
        : this.renderDefaultLink(item));
    return NAVBAR.filter(isActive).map(mapItem);
  }

  render() {
    const { counter, width } = this.state;
    const { history, projeto } = this.props;

    const renderer = ({ minutes, seconds, completed }) => {
      if (completed && this.hasFeature('controlar_tempo_sessao')) {
        toast.error(dictionary.AUSENTE, toastConfig);
        logout(history);
      }

      const { showModalTempoSessao } = this.state;
      if (+minutes === 0 && +seconds <= 30 && !showModalTempoSessao) {
        this.setState({ showModalTempoSessao: true, counter: 30000 });
      }

      return (
        <>
          {this.hasFeature('exibir_tempo_sessao') && (
            <span>
              Sua sessão expira em:{' '}
              <span className="countdown-style hidden-sm hidden-xs">
                {minutes}:{seconds}
              </span>
            </span>
          )}
          {this.hasFeature('exibir_modal_sessao') && (
            <ModalTempoSessao
              visible={showModalTempoSessao}
              minutos={minutes}
              segundos={seconds}
              message={this.getMessage('MODAL_TEMPO_SESSAO')}
              onConfirm={() => this.setState({ showModalTempoSessao: false })}
              onClose={() => this.setState({ showModalTempoSessao: false })}
            />
          )}
        </>
      );
    };

    const name = get(this.props, 'user.name');
    const codigo_verificacao = get(this.props, 'user.codigo_verificacao');
    const original_user = get(this.props, 'user.original_user');
    const isIframe = JSON.parse(localStorage.getItem('iframe')) || false;
    return (
      <div>

        {!isIframe && (
          <>
          <nav className="navbar-nv1">
            <div className="container">
              <div className="row">
                <div className="col-4 col-sm-8 col-md-5 col-logo">
                  <div className="info-topo-logo-title">
                    <figure className="logo-topo">
                      {this.props.shouldRenderMenu ? (
                        <Link to="/">
                          <img
                            src={imgLogo}
                            alt="img-logo"
                            className="img-responsive"
                          />
                        </Link>
                      ) : (
                        <img
                          src={imgLogo}
                          alt="img-logo"
                          className="img-responsive"
                        />
                      )}
                    </figure>
                    <figcaption className="info-modulo mt-1">
                    <span className="title-exame">{projeto.titulo}</span>
                    <span className="title-modulo">{projeto.subtitulo}</span>
                    </figcaption>
                  </div>
                </div>
                <div className="col-8 col-sm-4 col-md-7 col-info">
                  <div className="info-header">
                    <div className="count-navbar text-center">
                      <Countdown
                        date={Date.now() + counter}
                        renderer={renderer}
                      />
                    </div>
                    {original_user && (
                      <span className="label-user">{original_user}</span>
                    )}
                    <span className="label-user ml-2">
                      {original_user && <strong>ACESSANDO COMO: </strong>}
                      <span className="hidden-xs mr-2 ml-2">{name}</span>
                      {this.hasFeature('modal_supervisor_4_correcao') ? (
                        <div className="token-mobile">
                          {' '}
                          #{codigo_verificacao}
                        </div>
                      ) : (
                        ''
                      )}
                    </span>
                    <div className="profile-header">
                      <div className="btn-nav-mobile">
                        <button
                          type="button"
                          className="btn-navbar"
                          onClick={() => this.togglePopover('navBarMobile')}
                        >
                          <i className="fas fa-bars" />
                        </button>
                      </div>
                      <button
                        onClick={this.handleLogout}
                        type="button"
                        className="btn-logout"
                        id="_logout"
                      >
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        <>
        {
          <Fragment>
            <nav
              className={`${
                width > 990
                  ? 'navbar-nv2 hidden-xs hidden-sm'
                  : `${this.getPopoverClass(
                      'navBarMobile',
                    )} hidden-md navbar-nv2-mobile`
              }`}
            >
              <div className="container pt-0 pb-0">
                <div className="navbar-supervisor">
                  {this.props.shouldRenderMenu && this.renderMenu()}
                </div>
              </div>
            </nav>
          </Fragment>
        }
        </>
          </>
      )}
      </div>
    );
  }
}

NavbarGeral.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  onLogout: PropTypes.func,
  avisos: PropTypes.arrayOf(
    PropTypes.shape({
      descricao: PropTypes.string,
    }),
  ),
  shouldRenderMenu: PropTypes.bool,
  projeto: PropTypes.shape(),
};

NavbarGeral.defaultProps = {
  avisos: [],
  onLogout: () => {},
  shouldRenderMenu: true,
  projeto: {},
};

export default withRouter(NavbarGeral);
